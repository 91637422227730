<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col lg="6" md="8" sm="10" xs="12">
        <h2 class="white--text text-center mb-5">Welcome to your Sony Consumer Rebate Center</h2>
        <v-card class="elevation-12">
          <v-toolbar dark color="info">
            <v-toolbar-title>
              <span> What type of Sony Consumer are you?</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: on }">
                <v-btn icon v-on="on" @click="onClear()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Cancel") }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-list>
              <v-list-item :href="urlEndUser">
                <v-list-item-content>
                  <v-list-item-title>
                    I am a Sony Electronics Consumer
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-list-item :href="urlChannelPartner">
              <v-list-item-content>
                <v-list-item-title>
                  I am a Sony Authorized Dealer or Retails Sales Associate
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.block-card {
  border: solid 2px #ffffff !important;
  height: 200px;
}
.block-card a:link,
a:visited {
  text-decoration: none;
  color: white;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  name: "CeurLanding",
  metaInfo: {
    title: "Sony Consumer or RSA"
  },
  computed: {
    ...mapGetters(["selectedLocale", "selectedCountry"]),
    urlEndUser() {
      let url = "";
      if (this.selectedCountry.name == "USA") {
        url = "//" + process.env.VUE_APP_PROGRAM_CEUR_US_DOMAIN;
      }
      if (this.selectedCountry.name == "CAN") {
        url = "//" + process.env.VUE_APP_PROGRAM_CEUR_CA_DOMAIN;
      }
      return url;
    },
    urlChannelPartner() {
      let url = "";
      if (this.selectedCountry.name == "USA") {
        url = "//" + process.env.VUE_APP_PROGRAM_CCPR_US_DOMAIN;
      }
      if (this.selectedCountry.name == "CAN") {
        url = "//" + process.env.VUE_APP_PROGRAM_CCPR_CA_DOMAIN;
      }
      return url;
    }
  }
};
</script>
